module.exports = function () {

  return {
    VERSION : '2.0.0',
    SERVER_TYPE: ENUM.SERVER_TYPE.LOCAL,

    DOMAIN_WEB: 'https://getwidget.iweekender.com',
    DOMAIN_RPC: 'https://api.iweekender.com',

    DOMAIN_WIDGET: 'https://map.iweekender.com',

    GOOGLE_MAP_KEY: 'AIzaSyAMS5SaRzcYz-vtQkpFsaHfBa9vu3sNCy4',


    DEFAULT_LANGUAGE: ENUM.LANGUAGE.EN,
    DEFAULT_REGION  : ENUM.REGION.EN,

    SCRIPT_GOOGLE_ANALYTICS : true,
    SCRIPT_GTAG             : true,
    SCRIPT_YANDEX_METRIKA   : true,
    SCRIPT_INTERCOM         : true,

    LANGUAGE_LOCAL_TRANSLATE: false,
  }
}
