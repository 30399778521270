
import CONST from '@/../config/init.js';

// import getAllAttributes from "./function/getAllAttributes";
import makeCompletePath from "./function/makeCompletePath";
import getStyle         from "./function/getStyle";


let iframeInit = function ($el, option) {

  $el = (typeof $el === 'string') ? window.document.getElementById($el) : $el;
  option = option || {};

  if(!$el){
    console.error('[IWEEKENDER-WIDGET] Not correct iframe init element');
    return;
  }

  // TODO: fix and use event_id only!!!
  // let attrs = getAllAttributes($el);
  // attrs = Object.assign({}, option, attrs);
  let attrs = Object.assign({}, option);

    let offerContent  =  '';
  offerContent      += getStyle({frameHeight: option.frameHeight}); // TODO '<link href="' + CONST.url.webServerBase + '/widget-map/fix-style.css" rel="stylesheet"/>';
  offerContent      += '<iframe scrolling="no" class="iweekender-iframe" src="'+makeCompletePath(CONST.url.webWidget, attrs)+'"></iframe>';

  $el.innerHTML = offerContent;
  let iFrame = $el.getElementsByClassName('iweekender-iframe')[0];

};

global.iw_getWidget = {
  create: iframeInit,
  autoInit: function ($el, option) {
    document.addEventListener('DOMContentLoaded', function () {
      global.iw_getWidget.create($el, option);
    });
  }
};

export default global.iw_getWidget;
