export default function (pub, priv) {
  return {

    security: priv.SECURITY,

    version: pub.VERSION,
    serverType: pub.SERVER_TYPE,

    url:{
      apiServerBase: pub.DOMAIN_RPC,
      webServerBase: pub.DOMAIN_WEB,

      webWidget    : pub.DOMAIN_WIDGET,
    },

    script: {
      googleMapKey    : pub.GOOGLE_MAP_KEY,
      googleAnalytics : pub.SCRIPT_GOOGLE_ANALYTICS,
      gTag            : pub.SCRIPT_GTAG,
      yandexMetrika   : pub.SCRIPT_YANDEX_METRIKA,
      intercom        : pub.SCRIPT_INTERCOM,
    },
    language: {
      localTranslate: pub.LANGUAGE_LOCAL_TRANSLATE,
    }
  }
}
