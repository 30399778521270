module.exports = function () {

  return {
    VERSION : '2.0.0',
    SERVER_TYPE: ENUM.SERVER_TYPE.LOCAL,

    DOMAIN_WEB: 'http://getwidget.local.iweekender.com:8095',
    DOMAIN_RPC: 'https://api.iweekender.com',

    DOMAIN_WIDGET: 'http://widget.local.iweekender.com:8098',

    GOOGLE_MAP_KEY: 'AIzaSyAMS5SaRzcYz-vtQkpFsaHfBa9vu3sNCy4',


    DEFAULT_LANGUAGE: ENUM.LANGUAGE.EN,
    DEFAULT_REGION  : ENUM.REGION.EN,


    SCRIPT_GOOGLE_ANALYTICS : false,
    SCRIPT_GTAG             : false,
    SCRIPT_YANDEX_METRIKA   : false,
    SCRIPT_INTERCOM         : true,

    LANGUAGE_LOCAL_TRANSLATE: false,
  }
}
