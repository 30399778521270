// Вспомогательная функция формирования URL по аттрибутам
// Возвращает URL типа:
// https://iweekender.com/widget/shortWidget/?event_id=72946&venue_latitude=23.2323&venue_longitude=33.3333&start_date=2019-12-21&end_date=2019-12-25
//

let createGetParamsUrl = function(attrs) {
  let tail = '?';
  for (let name in attrs) {
    let value = attrs[name];
    if(typeof value === 'object' ){
      value = JSON.stringify(value);
    }
    value = encodeURIComponent(value);

    tail = "" + tail + name + "=" + value + "&";
  }

  // tail = tail.slice(0, tail.length - 1);
  tail += "domain="  + window.location.hostname;

  return tail;
};

let makeCompletePath = function(basePath, attrs) {
  let iframeTypeDefault = 'eventById';
  let iframeType = {
    eventById   : 'eventById',
    eventByData : 'eventByData',
  };

  let type = attrs['type'] || iframeTypeDefault;
  if(!iframeType.hasOwnProperty(type)){
    console.error('IWEEKENDER IFRAME TYPE ERROR. (used default iframe type)')
    type = iframeTypeDefault;
  }

  console.log('IWEEKENDER TYPE', type)

  return basePath + '/widget-init' + createGetParamsUrl(attrs);
};

export default makeCompletePath