export default function (options) {
  return `
<style>
/*#white-label {*/
/*  width: 100%;*/
/*  !*height: auto !important;*!*/
/*  min-height: 500px;*/
/*  margin: 0;*/
/*}*/
iframe.iweekender-iframe {
  width: 100%;
  height: ${options.frameHeight || 500}px;
  max-height: none !important;
  overflow: hidden;
  /*border: 1px solid #C8C8C8;*/
  border: none;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
</style>`;
}